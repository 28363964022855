.footimg div {
  height: 90px;
  width: 90px;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  border-radius: 60px;
}

.footimg img {
  height: 80px;
  width: 80px;
}

.twelve {
  /* background-color: black; */
  background: linear-gradient(to top, #42275afb, #964d8bd0);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.foot1 {
  /* margin-top: 35px !important; */
  /* background-color: #000000; */
}

.foot1 .foothead {
  color: #000000 !important;
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
}

.foot a {
  text-decoration: none;
}

.foot a p span {
  color: #ffffff;
  position: relative;
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 5px;
}

.foot a p span::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  border-radius: 4px;
  background: linear-gradient(to left, #40e0d0, #ff8c00, #ff0080);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.foot a p span:hover::before {
  transform-origin: left;
  transform: scaleX(1);
  /* padding-top: 5px; */
}

.foothead1 {
  /* margin-top: 2rem; */
}

/* .social i {
    color: #ffffff;
    cursor: pointer;
    font-size: 25px;
    padding: 7px;
    border-radius: 100px;
    border: 2px solid #000000;
}

.social i:hover {
    color: #ff00aa;
    background-color: #000000;
    border: none;
    border: 1px solid #ffffff;
} */

.footer-icon {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icon li {
  /* position: relative; */
  list-style: none;
  margin: 20px 10px;
  cursor: pointer;
  /* padding-bottom: 10px; */
}

.footer-icon li a {
  text-decoration: none;
}

.footer-icon li a .fa {
  font-size: 2.2em;
  color: #222;
}

.footer-icon li a::before {
  font-family: "FontAwesome";
  /* position: absolute; */
  /* top: -12px; */
  left: 0;
  font-size: 2em;
  /* padding: 10px 0px; */
  /* margin-bottom: 50px; */
  height: 0;
  /* overflow: hidden; */
  transition: 0.5s ease-in-out;
}

.footer-icon li i:hover {
  /* margin-top: -10px !important;
  transform: translateY(-15px) !important; */
}

.footer-icon li:nth-child(1) a::before {
  content: "\f16d";
  background-image: linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* border-bottom: 2.2px solid #dc2743; */
}

.footer-icon li:nth-child(2) a::before {
  content: "\f082";
  color: #3b5998;
  /* border-bottom: 2.2px solid #3b5998; */
}

.footer-icon .bi-twitter- {
  /* content: "\F8DB"; */
  height: 28px;
  width: 28px;
  /* border-bottom: 2.2px solid #1da1f2; */
}

.footer-icon li:nth-child(3) a::before {
  content: "\f232";
  color: #25d366;
  /* border-bottom: 2.2px solid #25d366; */
}

.footer-icon li:nth-child(4) a::before {
  content: "\f095";
  color: #0a7ac9;
  /* border-bottom: 2.2px solid #098db5; */
}

.footer-icon li:hover {
  /* height: 55px; */
  /* background-color: #000000; */
  transform: translateY(-10px);
  /* margin-top: -10px; */
  /* border-bottom: 2px solid white; */
}

/* .footer-icon {
  position: relative;
  display: flex;
  justify-content: center;
}

.footer-icon li {
  position: relative;
  list-style: none;
  margin: 20px 10px;
  cursor: pointer;
}

.footer-icon li a {
  text-decoration: none;
}

.footer-icon li a .fa {
  font-size: 2.2em;
  color: #222;
}

.footer-icon li a::before {
  font-family: "FontAwesome";
  position: absolute;
  top: -12px;
  left: 0;
  font-size: 2.2em;
  height: 0;
  overflow: hidden;
  transition: 0.5s ease-in-out;
}

.footer-icon li:nth-child(1) a::before {
  content: "\f16d";
  background-image: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-bottom: 2.2px solid #dc2743;
}

.footer-icon li:nth-child(2) a::before {
  content: "\f082";
  color: #3b5998;
  border-bottom: 2.2px solid #3b5998;
}

.footer-icon li:nth-child(3) a::before {
  content: "\f099";
  color: #1da1f2;
  border-bottom: 2.2px solid #1da1f2;
}

.footer-icon li:nth-child(4) a::before {
  content: "\f232";
  color: #25d366;
  border-bottom: 2.2px solid #25d366;
}

.footer-icon li:hover a::before {
  height: 55px;
} */