.bi-telephone-fill {
  color: #0a7ac9;
}

.bi-whatsapp {
  color: #25d366;
}

.bi-instagram {
  background-image: linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bi-facebook {
  color: #1da1f2;
}

.bi-twitter-x {
  height: 20px;
  width: 20px;
  margin-top: -3px;
}

.icon-bar {
  position: fixed;
  z-index: 9999;
  top: 70%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 3px;
  margin: 5px 0px;
  height: 45px;
  width: 45px;
  transition: all 0.3s ease;
  color: rgb(255, 255, 255);
  font-size: 23px;
  background-color: #070007;
  border-radius: 100px;
  border: 2px solid rgb(163, 50, 148);
}

.icon-bar a:hover {
  background-color: #6c1a70;
  border: 2px solid black;
}

/* .pack-label label:nth-child(1) {
  position: absolute;
} */

.pack-label {
  /* height: 50px;
  width: 300px; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.pack-label img {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  height: 50px;
  object-fit: cover;
  border-radius: 8px;
  /* filter: blur(0.9px); */
  width: 300px;
}

.pack-label label {
  width: 300px;
  position: relative;
  z-index: 2;
  font-weight: 600;
  font-size: calc(1rem + 0.4vw);
  color: #000000;
  padding-left: 10px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.container {
  /* max-width: 800px; */
  margin: 0 auto;
  padding: 20px;
}

.head-label {
  display: flex;
  justify-content: space-around;
}

.mainparent {
  display: flex;
  flex-wrap: wrap;
}

.package {
  border-radius: 12px;
  background-color: #F4ECF7;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.parent h5 {
  text-align: center;
  padding: 10px 0px;
  border-radius: 8px;
  color: black;
  font-weight: 500;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 2px;
  margin: 15px 0px;
}

#parent1 h5 {
  background-color: #EBDEF0;
}

.main-parent .parent:nth-child(1) .child:nth-child(2) {
  background-color: #EBDEF0;
}

#parent2 h5 {
  background-color: #D4E6F1;
}

.main-parent .parent:nth-child(2) .child:nth-child(2) {
  background-color: #D4E6F1;
}

#parent3 h5 {
  background-color: #FADBD8;
}

.main-parent .parent:nth-child(3) .child:nth-child(2) {
  background-color: #FADBD8;
}

#parent4 h5 {
  background-color: #D1F2EB;
}

.main-parent .parent:nth-child(4) .child:nth-child(2) {
  background-color: #D1F2EB;
}

.parent h5:nth-child(1) {
  /* background-color: green; */
}

.content {
  display: none;
  background-color: white;
  margin: 30px 0px;
  padding: 30px 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.container h2 {
  text-align: center;
  color: #000000;
  font-weight: 500;
}


/* Only show content when corresponding radio is checked */
#page1:checked~#content1,
#page2:checked~#content2 {
  display: block;
}

.main-parent {
  padding: 10px;
  display: grid;
  justify-content: center;
  grid-gap: 0px 15px;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
}

.child li {
  list-style-type: none;
  margin-top: 6px;
  font-size: 15px;
  /* word-break: break-all; */
  /* background-color: blue; */
  /* word-wrap: initial; */
  /* width: 50px; */
  /* padding-left: 50px; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */

}


.child li:last-child::before {
  content: " ";
  /* padding-left: 15px; */
}

.child li::before {
  content: "✦";
  padding-right: 10px;

}

.child ul {
  padding: 0 0 0 0px;
  margin: 0;
}

.child h4 {
  /* text-align: center; */
}



@media screen and (max-width:630px) {
  .pack-label label {
    width: 210px;
  }

  .pack-label img {
    width: 210px;
  }

  .main-parent {
    display: block;
  }
}

@media screen and (max-width:442px) {
  .package {
    background-color: #F4F6F6;
  }

  .pack-label label {
    width: 140px;
    height: 40px;
    background-color: #ebebeb73;
  }

  .pack-label img {
    width: 140px;
    height: 40px;
    object-fit: cover;
  }
}

.parent {
  margin: 10px 0px;
  cursor: pointer;
  padding: 0;
  /* min-width: 31%; */
}


.child {
  /* background-color: #bebebe; */
  padding: 15px 10px;
  border-radius: 5px;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.child a {
  padding: 10px 50px;
  background-color: #5c3970d8;
  border-radius: 12px;
  margin: 10px 10px;
  text-decoration: none;
  color: white;
  font-weight: 700;
}

.child a:hover {
  background-color: #945ab8;
}

.child.hidden {
  display: none;
}

.active-parent {
  background-color: #ddd;
}


.package input {
  display: none;
}




/* #612f61 

#848484

#5b4f5b
*/
body {
  font-family: "Sans";
}

@media screen and (max-width: 400px) {
  .our-categories .img-div {
    height: 140px !important;
  }

  .our-categories h2 {
    font-size: calc(1rem + 0.1vw) !important;
    letter-spacing: 1px;
  }
}

.our-categories h2 {
  font-size: calc(1rem + 0.8vw);
  letter-spacing: 1.8px;
}

.our-categories p {
  font-size: calc(0.6rem + 0.4vw);
  padding: 10px 15px;
  letter-spacing: 0.2px;
}

.our-categories div:nth-child(1) {
  border-radius: 8px;
}

.main-categories .one {
  background-color: #f9ebea;
}

.main-categories .two {
  background-color: #fdf2e9;
}

.main-categories .three {
  background-color: #ebf5fb;
}

.main-categories .four {
  background-color: #eafaf1;
}

.main-categories .five {
  background-color: #feeef5;
}

.main-categories .six {
  background-color: #faeefe;
}

.main-categories .seven {
  background-color: #e8fcf4;
}

.our-categories a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 8px;
  transition: all 0.5s !important;
}

.our-categories:hover {
  margin-top: -2px !important;
  /* transform: translateY(-10px) !important; */
  transition: all 0.5s !important;
}

.our-categories .img-div {
  height: 180px;
}

.our-categories img {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  object-fit: cover;
  width: 310px;
  height: 100%;
  border-radius: 100px 8px 8px 100px;
}

.kk {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("http://localhost:3000/static/media/marry1.2b6797d239072439bab2.gif");
}

.main-banner {
  height: 50vh;
}

.banner1textin1 {
  padding: 20px 0px;
  position: relative;
  z-index: 1;
  background: #f4ecf7ce;
  box-shadow: rgba(255, 255, 255, 0.146) 0px 1px 2px 0px,
    rgba(255, 255, 255, 0.202) 0px 2px 6px 2px inset;
  backdrop-filter: blur(15.5px);
  -webkit-backdrop-filter: blur(15.5px);
  border-radius: 22px;
  border: 1px solid rgba(157, 73, 240, 0.134);
  font-size: 18px;
  /* background-image: url(https://i.pinimg.com/originals/b8/3f/2b/b83f2b37c45bd234a512fa4503426d62.jpg); */
}

.banner1img {
  position: absolute;
  height: 110%;
  width: 100%;
  z-index: 0;
  filter: blur(5px);
  background-size: cover;
  background-attachment: fixed;
  background-image: url("https://png.pngtree.com/thumb_back/fh260/background/20201124/pngtree-floral-purple-watercolor-background-image_498978.jpg");
}

.banner1text h1 {
  /* font-size:3vw; */
  margin-bottom: 4%;
  letter-spacing: 2px;
}

.banner1text p {
  font-size: 2.2vh;
  padding: 0;
  /* margin: 5px; */
  /* line-height: 40px; */
  letter-spacing: 1px;
}

.banner1text {
  padding: 2%;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;

  text-align: center;
  /* line-height: 40px; */
  color: rgb(0, 0, 0);
  /* letter-spacing: 2px; */
  font-weight: 500;
}

.banner {
  /* background-color: brown; */
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  /* width: 100%; */
  height: 80vh;
  /* background-color: #3f92c9; */
}

.bannerimg {
  position: absolute;
  /* top: 550px; */
  height: 90vh;
  min-width: 100%;
  filter: blur(3px);
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  /* background-color: #ffffff32;
  background-blend-mode: color; */
  background-attachment: fixed !important;
  /* background-image: url("https://i.pinimg.com/originals/97/19/96/971996011f2af8e98c9f9e0e1c5d63c9.gif"); */
  background-image: url(https://i.pinimg.com/originals/b8/3f/2b/b83f2b37c45bd234a512fa4503426d62.jpg);
}

@media screen and (max-width: 576px) {
  .icon-bar a {
    padding: 0px;
    height: 40px;
    width: 40px;
    font-size: 23px;
  }

  .banner {
    height: 460px !important;
  }

  .bannerimg {
    height: 440px !important;
  }

  .bannertext {
    height: 400px !important;
    font-size: 11px !important;
    line-height: 25px !important;
    letter-spacing: 2px !important;
  }

  .bannertext div {
    height: 380px !important;
    width: 280px !important;
    padding: 5px !important;
  }

  .bannertext p {
    margin-top: 15px !important;
  }
}

.bannertext {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 500px !important; */
  width: 500px;
  /* padding: 100px; */
  text-align: center;
  line-height: 35px;
  color: #000000;
  font-size: 15.6px;
  letter-spacing: 2.5px;
  font-weight: 900;
}

.bannertext div {
  border-radius: 10px;
  height: 580px;
  padding: 50px 30px;
  background: rgba(255, 255, 255, 0.101);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* border: 1px solid rgba(255, 254, 254, 0.918);
  background: #ffffff50;
  box-shadow: inset 1px 1px 10px #dbdbdb, inset -1px -1px 10px #dbdbdb; */
}

.bannertext p {
  margin-top: 30px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* #one:active #two{
    display: none;
} */

/* .main {
    background-color: rgb(255, 255, 255);
    height: 500px !important;
} */

/************** crousel section *************/
.mainhead {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head {
  position: absolute;
  /* top: 75px; */
  min-height: 100vh;
  width: 100%;
  filter: blur(1px);
  z-index: -1;
  background-attachment: fixed;
  background-size: cover;
  /* background-position: center; */
  background-repeat: no-repeat;
  background-image: url(https://i.pinimg.com/564x/f5/72/9d/f5729de965dbd52bc8694bc74f12386b.jpg);
  /* background-image: url(https://i.pinimg.com/564x/42/98/5b/42985bb80d230cbf0e2f403f38619027.jpg); */
}

@media screen and (max-width: 576px) {
  .mainhead {
    height: 30vh !important;
  }

  .head {
    filter: blur(2px);
    background-repeat: no-repeat, repeat;
    background-size: 150vh;
    background-position: center top;
  }

  /* .imageinnertxt1 {
        height: 200px !important;
        padding: 20px !important;
        letter-spacing: 1px !important;
    } */

  .imageinnertxt1 .logo1 {
    height: 50px !important;
    width: 55px !important;
  }

  .imageinnertxt1 .logoname1 {
    margin-top: 0 !important;
    height: 25px !important;
  }

  .imageinnertxt1 h1 {
    font-size: 1.6rem !important;
  }

  .imageinnertxt1 p {
    font-size: 1rem !important;
  }
}

.imageinnertxt1 {
  /* position: absolute; */
  /* z-index: -1; */
  /* filter: blur(2px); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  /* height: 320px; */
  /* width: 800px; */
  color: #000000;
  letter-spacing: 2px;
  background-color: #fbfbfb48;
  box-shadow: rgba(255, 255, 255, 0.623) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.527) -3px -3px 6px 1px inset;
  /* border: 1px solid rgb(255, 255, 255); */
}

.logoimages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageinnertxt1 .logo1 {
  /* height: 130px; */
  width: 140px;
}

.imageinnertxt1 .logoname1 {
  margin-top: 20px;
  height: 100px;
}

.imageinnertxt1 p {
  margin-top: 20px;
  font-size: 2.3rem;
}

/* ********** SECOND SECTION**************** */

.textimg {
  /* border: 1px solid black; */
  z-index: -1;
  width: 100%;
  height: 75px;
  position: absolute;
  filter: blur(3px);
  background-image: url(https://i.pinimg.com/564x/a9/d6/83/a9d683ac3cbb4e533e2705bed39061e4.jpg);
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
  background-attachment: fixed;
}

.textimgtext h1 {
  /* margin: 0; */
  padding: 15px 0px 0px 0px;
  color: white;
  letter-spacing: 10px;
  /* background: linear-gradient(to left, #ffffffe1, #cb5ff53f); */

  /* box-shadow: rgba(8, 0, 0, 0.075) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */
}

/* **********  THIRD SECTION  ******** */

@media screen and (max-width: 576px) {
  .services1 {
    grid-gap: 20px !important;
  }

  .services-card {
    height: 160px !important;
    width: 140px !important;
  }

  .services-card-img {
    height: 160px !important;
    width: 140px !important;
  }

  .card-p {
    font-size: 14px !important;
  }
}

.services1 {
  display: flex;
  grid-gap: 40px;
  padding: 50px;
  justify-content: center;
  flex-wrap: wrap;
  background-color: white;
}

.services-card {
  height: 200px;
  width: 175px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  transition: all 0.5s;
}

.services-card:hover {
  transform: scale(1.1);
  transition: all 0.5s;
}

.services-card-img {
  position: absolute;
  overflow: hidden;
  border-radius: 6px;
  height: 200px;
  width: 175px;
  object-fit: cover;
  cursor: pointer;
  transition: 0.5s;
}

.services-card-img img {
  background-color: #000000 !important;
  background-blend-mode: color !important;
}

.card-p {
  margin-bottom: 20px;
  font-weight: 600;
  padding: 3px;
  font-size: 15.5px;
  letter-spacing: 0.5px;
  color: white;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  position: relative;
  background-color: #000000b2;
  transition: 1s;
  cursor: pointer;
}

.services-card-img:hover {
  transform: scale(1.05);
}

.services-card:hover .card-p {
  margin-bottom: 50%;
  background-color: #a30481cb;
}

.img-area {
  text-align: center;
  color: #fff;
  position: relative;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
  /* padding: 1000px; */
  width: 160px;
  height: 160px;
  box-shadow: rgba(0, 0, 0, 0.637) 0px 3px 8px;
}

.pick {
  /* min-height: 35rem; */
  /* margin-top: 5px; */
  padding: 40px 140px 0px 95px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: #447450; */
}

.pick div {
  padding: 0px;
}

.img-area img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  margin: 0;
  transition: all 1.1s;
  object-fit: center;
}

@media screen and (max-width: 576px) {
  .img-area {
    height: 160px;
    width: 130px;
  }

  .pick {
    padding: 0;
  }
}

.img-area:hover img {
  transform: scale(1.1);
}

.img-text {
  font-weight: 500;
  font-size: 18px;
}

.img-text,
.img-area::after {
  width: 100%;
  position: absolute;
  left: 0;
}

.img-area::after {
  content: "";
  height: 100%;
  top: 0;
  transition: 0.5s;
}

.img-area:hover::after {
  /* background: linear-gradient(to bottom, rgba(199, 0, 57, .01)0, rgba(199, 0, 57, .25)25%, rgba(199, 0, 57, .75), rgba(199, 0, 57, .95)100%); */
  background: linear-gradient(to bottom,
      rgba(199, 0, 57, 0.01) 0,
      rgba(199, 0, 57, 0.25) 25%,
      rgba(199, 0, 57, 0.75),
      rgba(199, 0, 57, 0.95) 100%);
  opacity: 1.5;
  /* border: 1px solid black; */
}

.img-text {
  /* padding: 10px; */
  margin-bottom: 40px;
  bottom: 0;
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.13, 0.62, 0.81, 0.91) 0s;
  transform: translateY(145px);
}

.img-area:hover .img-text {
  transform: translateY(0);
}

/* @media screen and (max-width: 576px) {
    .child img {
        height: 300px;
        width: 220px;
    }
}

@media screen and (max-width: 576px) {
    .child {
        height: 250px;
        width: 220px;
    }
} */

/* ********** SCROLL section  *********** */
@media screen and (max-width: 576px) {
  .infinite-scroll {
    height: 200px !important;
  }

  .scroll-img img,
  .scroll-img-one img {
    height: 150px !important;
    width: 150px !important;
  }
}

.scroll {
  /* background: #ffffff; */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* filter: blur(5px); */
  /* background-image: url(https://media.istockphoto.com/id/1215301248/video/abstract-beautiful-double-bokeh-light-blurred-glowing-background-concept-for-wedding-card.jpg?s=640x640&k=20&c=RMd5H_uP7AVx7DUdGtgAWf5Quy47DbrLF7R7f_1IBj8=); */
}

.infinite-scroll {
  height: 300px;
  position: relative;
  overflow: hidden;
}

.scroll-img,
.scroll-img-one {
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  white-space: nowrap;
  animation: scrollimg 12s linear infinite;
}

.scroll-img-one {
  animation: scrollimgone 12s linear infinite;
}

.scroll-img img,
.scroll-img-one img {
  margin: 0 0.8em;
  width: 200px;
  height: 200px;
  object-fit: cover;
  /* padding: 10px; */
  background-color: #e8f8f5;
  border-radius: 6px;
  transition: all 0.5s;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.scroll-img img:hover,
.scroll-img-one img:hover {
  background-color: #8eecd9;
  transform: scale(1.08);
  box-shadow: none;
  cursor: pointer;
  border-radius: 6px;
}

@keyframes scrollimg {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-50%, 0);
  }
}

@keyframes scrollimgone {
  0% {
    transform: translate(-50%, 0);
  }

  100% {
    transform: translate(0%, 0);
  }
}

/*  */