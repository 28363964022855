.terms img {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.terms-1 img {
    width: 100%;
    height: 500px;
    object-fit: contain;
}


@media screen and (max-width:1000px) {
    .terms img {
        height: 100%;
    }
}