.reception-tab {
  margin: 0 10px;
  cursor: pointer;
}

.reception-content {
  display: none;
  border-radius: 10px;
}

#reception-page1:checked~#reception-content1,
#reception-page2:checked~#reception-content2 {
  display: block;
}

.reception-container input {
  display: none;
}

.reception-arrow {
  display: flex;
  justify-content: center;
}

.reception-arrow i {
  text-align: center;
  border-radius: 100%;
  background: #ffffff;
  padding: 10px 12px;
  border: 1px solid #000000;
  color: #ff008c;
  cursor: pointer;
  transition: all 0.25s;
}

.reception-arrow i:hover {
  background-color: #ff008c;
  color: black;
}




.oo,
.haldi,
.mantapa,
.reception,
.naming,
.keeth {
  position: fixed;
  top: 0px;
  z-index: -1;
  height: 100%;
  width: 100%;
  filter: blur(4px);
  background-size: cover !important;
  background: url("../../asset/birthday/images.jpeg");
}

.haldi {
  background-position: center;
  background-image: url("https://cdn0.weddingwire.in/article/3122/3_2/960/jpg/102213-weddingnama.jpeg");
}

.mantapa {
  background-image: url("https://image.wedmegood.com/resized/800X/uploads/project/82707/1585566648_Siri_GM_33.jpg");
}

.reception {
  background: url("https://images.squarespace-cdn.com/content/v1/530286f8e4b0d1c4964d040e/1437595712682-DDAGTD79H1GPU7U2O5FZ/image-asset.jpeg?format=1000w");
}

.naming {
  background: url("https://i.pinimg.com/474x/d7/82/7e/d7827ea43ba7d945c76c050294daea50.jpg");
}

.keeth {
  background: url("https://i.pinimg.com/originals/df/d7/86/dfd786f08ad8ce1d1b55c3c70bc39316.jpg");
}

.birthday-banner {
  margin: 30px 0px;
  padding: 50px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #000000;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 1px;
}

.birthday-banner h2 {
  margin-bottom: 20px;
}




.catergoriesimg h1 {
  font-weight: bold;
  padding: 11px;
  text-align: center;
  color: #000000;
  letter-spacing: 11px;
  background: #ffffff7c;
  box-shadow: 0 8px 32px 0 #1f26875e;
  backdrop-filter: blur(15.5px);
  -webkit-backdrop-filter: blur(15.5px);
  border-radius: 10px;
  border: 1px solid #ffffff2e;
}

.firstrow {
  background-color: #f0f8ff00;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 46px;
  margin-bottom: 30px;
}

.firstrow div {
  height: 210px;
  display: flex;
  cursor: pointer;
  border-radius: 7px;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.534) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.616) 0px 2px 6px 2px;
}

.firstrow div :hover {
  transform: scale(1.3);
  transition: 500ms linear;
}

.firstrow img {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  object-fit: cover;
}

@media screen and (max-width: 576px) {
  .firstrow div {
    width: 143px;
    height: 165px;
  }

  .firstrow div :hover {
    transform: scale(1.1) !important;
  }

  .catergoriesimg h1 {
    letter-spacing: 5px;
  }


  .firstrow {
    grid-gap: 10px !important;
  }

  .birthday-banner {
    padding: 10px;
  }
}