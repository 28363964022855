.main-sub {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 741px) {
  .main-sub {
    /* height: 205vh !important; */
    margin: 20px 0px;

  }

  .sub-img {
    height: 205vh !important;

  }

  .back-sub-1 {
    /* height: 200vh !important; */
  }

  .card:hover .contents {
    visibility: hidden !important;
  }
}

.sub-img {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  position: absolute;
  z-index: -1;
  /* padding: 50px; */
  height: 100vh;
  min-width: 100%;
  /* filter: blur(5px); */
  filter: blur(0px);
  opacity: 0.8;
  background-size: cover !important;
  background: url("../../../asset/gold.png") no-repeat top fixed;
}

.back-sub-1 h1 {
  padding-bottom: 10px;
}

.back-sub-1 {
  min-height: 90vh;
  text-align: center;
  padding: 15px;
  position: relative;
  z-index: 2;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  background: rgba(255, 255, 255, 0.055);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.124);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.798);
  /* background-color: aquamarine; */
  /* position: relative !important;
  z-index: 6 !important; */
}

.fluid a {
  text-decoration: none !important;
}

.card {
  cursor: pointer;
  /* position: relative; */
  width: 250px;
  height: 150px;
  margin: 50px 0px 30px 0px;
  color: black !important;
  /* padding: 30px 0px; */
  border: none;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.5s;
}

.card:hover {
  height: 280px;
  border: none;
  /* background: #ebf5fb; */
}

.card i {
  color: rgb(0, 0, 0);
}

.card:hover i {
  transform: scale(1.05);
  transition: 0.5s ease;
  margin-left: 20px;
  color: blueviolet;
}

.card h6 {
  /* font-size: 18px; */
  font-weight: 900;
  text-align: center;
  position: relative;
  margin-bottom: -30px;
  color: rgb(0, 0, 0);
}

.fluid .card p {
  /* font-size: 13.5px; */
  /* margin-top: 10px; */
  color: #000000;
}

.card .imgBx {
  position: relative;
  width: 230px;
  height: 100px;
  top: -60px;
  left: 10px;
  z-index: 1;
  border-radius: 5px;
}

.card .imgBx img {
  width: 100%;
  height: 150px;
  border-radius: 5px;
  border: 3px solid rgb(0, 0, 0);
}

.card .contents {
  position: relative;
  margin-top: -20px;
  padding: 0px 10px;
  overflow: hidden;
  text-align: center;
  color: #000000 !important;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
}

.card:hover .contents {
  visibility: visible !important;
  transition: 0.5s;
  opacity: 1;
  overflow: hidden;
  margin-top: 0px;
  color: rgb(0, 0, 0) !important;
  transition-delay: 0.1s;
}