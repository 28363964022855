.aboutus-head-img {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* background-color: aqua; */
}

.aboutus-img {
  height: 50vh;
  width: 100%;
  position: absolute;
  z-index: -1;
  filter: blur(1px);
  background-size: cover !important;
  background: url("https://images.pexels.com/photos/1042152/pexels-photo-1042152.jpeg?cs=srgb&dl=pexels-atul11-1042152.jpg&fm=jpg") no-repeat 0% 70% fixed;
}

@media screen and (max-width: 576px) {
  .aboutus-head-img {
    height: 24vh !important;
  }

  .aboutus-img {
    background-size: cover !important;
    background: url("https://images.pexels.com/photos/1042152/pexels-photo-1042152.jpeg?cs=srgb&dl=pexels-atul11-1042152.jpg&fm=jpg") no-repeat 50% 22.7% fixed;
    height: 27vh !important;
  }

  .aboutustext h1 {
    font-size: 8vw !important;
  }

  .aboutus-head-img {
    height: 27vh !important;
  }
}

.aboutustext {
  padding: 5px;
  position: relative;
  z-index: 1;
  /* top: 30%; */
  text-align: center;
  /* background-color: aqua; */
}

.aboutustext h1 {
  text-shadow: 4px 1px rgb(0, 0, 0);
  padding: 1.5%;
  font-weight: 500;
  font-size: 4.5vw;
  letter-spacing: 5px;
  border-radius: 5px;
  /* box-shadow: rgba(204, 219, 232, 0.541) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.308) -3px -3px 6px 1px inset; */
  color: rgb(255, 255, 255);
  /* background-color: #ffffff86; */
}

@media screen and (max-width: 576px) {
  /* .aboutus {
        height: 100%
    } */
}

.about p {
  margin-top: 10px;
  letter-spacing: 0.5px;
}

.pp {
  background-color: rgba(0, 0, 0, 0.316);
  padding: 100px 0px;
  display: flex;
  justify-content: center;
  min-height: 40rem;
}

@media screen and (max-width: 576px) {
  .pp {
    padding: 50px 0px;

    min-height: 25rem !important;
  }
}

.swiper {
  width: 100% !important;
  padding-top: 50px !important;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px !important;
  height: 300px !important;
  border-radius: 10px;
}

@media screen and (max-width: 576px) {
  .swiper-slide {
    width: 200px !important;
    height: 200px !important;
  }
}

.swiper-slide img {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 10px;
  object-fit: cover;
  -webkit-box-reflect: below 1px linear-gradient(transparent, transparent, #0004);
}

.four-pics-content h5 {
  color: #612f61;
  letter-spacing: 2px;
  font-size: 1.6rem;
}

.fourpics {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  grid-gap: 10px;
}

.fourpics img {
  height: 250px;
  width: 230px;
  object-fit: cover;
  border-radius: 7px;
}

.fourpics img:hover {
  transform: scale(1.1);
  border-radius: 7px;
  transition: all 0.5s;
}

@media screen and (max-width: 576px) {
  .fourpics img {
    height: 150px;
    width: 140px;
  }

  .four-pics-content p {
    line-height: 24px;
  }
}
