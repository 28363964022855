input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 99999s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cart-head {
  height: 20rem;
  /* background-color: aqua; */
  /* display: flex; */
  align-items: flex-end;
  justify-content: center;
}

.cart-head-img {
  position: absolute;
  z-index: 0;
  height: 20rem;
  background-image: url("../../asset/cart/Image-8.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  background-color: rgba(0, 0, 0, 0.219);
  background-blend-mode: color;
}

.contactus-text {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 16rem;
  /* width: 50%; */
  position: relative;
  z-index: 1;
}

.contactus-text h1 {
  color: rgb(255, 255, 255);
  font-size: 48px;
  text-shadow: 3px 2px black;
  letter-spacing: 1px;
  /* background-color: rgba(252, 252, 252, 0.274); */
  /* margin: 0px 2rem 2rem 0px;  */
}

.cart-contact {
  color: rgb(37, 37, 37);
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(252, 252, 252); */
}

.cart-contact h2 {
  letter-spacing: 1px;
  font-size: 30px;
}

.cart-contact h3 {
  color: #612f61;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

.cart-contact p {
  letter-spacing: 1px;
  font-size: 15.5px;
  line-height: 30px;
}

.contact-form {
  height: 100vh;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-back {
  height: 100%;
  width: 100%;
  background-attachment: fixed;
  background-image: url(https://www.alfaazphotography.com/wp-content/uploads/2019/10/How-to-find-right-Indian-Wedding-Photographer-0028.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-blend-mode: color;
  filter: blur(3px);
  position: absolute;
  z-index: 1;
  padding: 10px !important;
}

.segment {
  /* height: 550px; */
  padding: 280px 0px 320px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: rgba(253, 253, 253, 0.322);
  filter: blur(0px);
  position: absolute;
  box-shadow: rgb(255, 255, 255) 3px 3px 6px 0px inset,
    rgb(255, 255, 255) -3px -3px 6px 1px inset;
  z-index: 1;
}

.segment1 {
  position: absolute;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
}

.segment1 h1 {
  margin-top: 15px;
  color: #612f61;
}

.segment1 h6 {
  letter-spacing: 1px;
  color: black;
}

.segment1 input,
textarea {
  margin-top: 15px;
  padding: 12px;
  outline: none;
  border: none;
  border-radius: 12px;
  box-shadow: rgba(85, 88, 90, 0.548) -3px -3px 3px 0px inset,
    rgba(0, 0, 0, 0.014) -3px -3px 6px 1px inset;
  background-color: rgba(255, 255, 255, 0.89);
  transition: 0.5s ease;
}

.segment1 :focus {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(85, 88, 90, 0.548) 3px 3px 3px 0px inset,
    rgba(0, 0, 0, 0.014) -3px -3px 6px 1px inset;
}

.segment1 ::placeholder {
  color: black;
  font-size: 15.5px;
  letter-spacing: 1px;
}

.segment1 button {
  padding-left: 5px;
  width: 130px;
  font-size: 18px;
  letter-spacing: 1.5px;
  height: 46px;
  border-radius: 20px;
  border: none;
  font-weight: 600;
  box-shadow: rgba(85, 88, 90, 0.808) -3px -3px 3px 0px inset,
    rgba(0, 0, 0, 0.712) -3px -3px 6px 1px inset;
  transition: 0.4s ease;
  background-color: rgb(255, 255, 255);
}


.success-message {
  color: #49ff3c;
  background-color: #000000;
  /* font-size: 19px; */
  font-weight: 900;
}

.segment1 button:hover {
  background-color: #612f61;
  color: rgb(255, 255, 255);
  box-shadow: rgba(255, 255, 255, 0.959) 4px 4px 4px inset;
  transform: scale(1.05);
}

@media screen and (max-width: 991px) {
  .cart-head {
    height: 12rem !important;
  }

  .cart-head-img {
    height: 11rem !important;
  }

  .contactus-text {
    justify-content: center !important;
    height: 10rem !important;
  }

  .contactus-text h1 {
    font-size: 35px !important;
  }

  .cart-contact h3 {
    margin-bottom: 10px !important;
  }

  .cart-contact h2 {
    font-size: 21px !important;
  }

  .cart-contact p {
    margin: 0 !important;
    letter-spacing: 0px !important;
    font-size: 13.5px !important;
    line-height: 20px !important;
    padding: 8px !important;
  }

  .contact-form {
    height: 70vh !important;
  }

  .form-back {
    background-size: cover !important;
    background-position: center !important;
    height: 70% !important;
  }

  .segment {
    /* height: 445px !important; */
    background-color: rgba(253, 253, 253, 0.493) !important;
    box-shadow: none !important;
  }

  .segment1 {
    padding: 5px !important;
  }

  .segment1 input,
  textarea {
    margin-top: 10px !important;
  }

  .segment1 h5 {
    font-size: 13px !important;
  }

  .segment1 h6 {
    letter-spacing: 0px !important;
  }

  .segment1 input,
  textarea {
    padding: 11px !important;
  }

  .segment1 textarea {
    height: 95px !important;
  }

  .contactus-content div {
    padding: 5px 0px 0px 0px !important;
  }

  .contactus-content p {
    margin-bottom: 10px !important;
  }

  .contactus-content p:nth-child(2) {
    font-size: 14px !important;
  }
}

.contactus-content {
  background-color: rgb(252, 252, 252);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactus-content div {
  padding: 30px 0px 10px 10px;
  border-bottom: 1px solid rgb(172, 172, 172);
}

.contactus-content h4 {
  letter-spacing: 0.5px;
  color: #612f61;
  font-weight: 600;
}

.contactus-content p:nth-child(2) {
  color: rgb(92, 92, 92);
  font-size: 15px;
}

.contactus-content span {
  font-size: 15.8px;
  padding-left: 5px;
  color: black;
}