li.borderYtoX a:before, li.borderYtoX a:after {
    position: absolute;
    opacity: 0.5;
    height: 100%;
    width: 2px;
    content: '';
    background: rgb(255, 255, 255);

}


li.borderYtoX a:before {
    left: 0px;
    top: 0px;

}

li.borderYtoX a:after {
    right: 0px;
    bottom: 0px;

}

li.borderYtoX a:hover:before, li.borderYtoX a:hover:after {
    opacity: 1;
    height: 2px;
    width: 100%;
    transition: 0.3s;
}


.animated-icon1 {
    width: 22px;
    height: 20px;
    position: relative;
    margin: 5px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

.animated-icon1 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

.animated-icon1 span {
    background: #000000;
}


.animated-icon1 span:nth-child(1) {
    top: 0px;
}

.animated-icon1 span:nth-child(2) {
    top: 7px;
}

.animated-icon1 span:nth-child(3) {
    top: 14px;
}

.animated-icon1.open span:nth-child(1) {
    top: 11px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.animated-icon1.open span:nth-child(3) {
    top: 11px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.navbar-nav li:hover>ul.dropdown-menu {
    display: block;
    background-color: rgb(255, 255, 255);
    border: none;
}

.dropdown-menu>li>a {
    color: #000000;
    text-align: center;
    width: 180px;
    border-bottom: 1px solid rgba(116, 116, 116, 0.288);
    transition: color .3s ease-in-out;
    box-shadow: .3s ease-in-out;
    font-size: 13px;
    letter-spacing: 1px;
    text-decoration: none;

}

.dropdown-menu>li>a:hover {
    color: rgb(255, 255, 255);
    box-shadow: 200px 0 0 rgba(113, 1, 128, 0.616) inset;
}

.dropdown-submenu {
    position: relative;

}

@media screen and (max-width:576px) {
    .dropdown-submenu>.dropdown-menu {
        top: 105% !important;
        left: 10% !important;
        border: 1px solid black !important;
    }

    .nav-item .nav-link:hover {
        color: rgb(0, 0, 0) !important;
    }
}

.dropdown-submenu>.dropdown-menu {
    top: 0;
    left: 100%;

}

.dropdown-menu>li>a:hover::after {
    text-decoration: none !important;
    transform: rotate(-90deg);
}

nav {
    background: linear-gradient(-45deg, #9c31f3cc, #e7c6d3c9, #f9fde0c9, #989899);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.navbar-nav {
    display: flex;
    justify-content: space-evenly;
    width: 65%;
}

.navbar-brand {
    width: 200px;
}


@media screen and (max-width: 991px) {
    .nav-item .nav-link {
        width: 150px !important;
    }
}

.nav-item .nav-link {
    /* background-color: #590261; */
    min-width: 50px;
    font-size: 13.8px;
    letter-spacing: 1.4px;
    /* margin-left: 30px; */
    color: rgb(0, 0, 0);
    cursor: pointer;
    font-weight: 500;
}

.nav-item .nav-link:hover {
    color: rgb(255, 255, 255);
    transform: scale(1.1);
}

.logo {
    height: 50px;
    width: 55px;

}

.logoname {
    height: 23px;
    width: 185px;
    margin-left: -10px;
}